import Axios from "axios";
import { utils } from "./http-common";
import { OrganisationsApi } from "./organisation-api";
import { SafesApi } from "./safes-api";

export class LookupsApi {
	static async fetchIndustries() {
		return Axios.get(utils.generateUrl(`industry`), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchOrgTypes() {
		return Axios.get(utils.generateUrl("organisations/types"), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchVendors() {
		return Axios.get(utils.generateUrl("api/admin/Vendor", process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchSafeTypes() {
		return Axios.get(utils.generateUrl("api/admin/Type/SafeType", process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchAdjustmentReasons() {
		return Axios.get(utils.generateUrl("api/admin/Type/AdjustmentReasons", process.env.VUE_APP_SAFES_URL), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchProvinces() {
		return Axios.get(utils.generateUrl("locations/province"), {
			headers: await utils.generateHeader()
		});
	}

	static async fetchLookups(lookupType: string) {
		switch (lookupType) {
			case "categories": {
				// Needs to be added
				break;
			}
			case "extras": {
				// Needs to be added
				break;
			}
			case "options": {
				// Needs to be added
				break;
			}
			case "industries": {
				return LookupsApi.fetchIndustries();
			}
			case "vendors": {
				return LookupsApi.fetchVendors();
			}
			case "safeTypes": {
				return LookupsApi.fetchSafeTypes();
			}
			case "adjustmentReasons": {
				return LookupsApi.fetchAdjustmentReasons();
			}
			case "orgTypes": {
				return LookupsApi.fetchOrgTypes();
			}
			case "organisations": {
				let organisations = null;
				await OrganisationsApi.fetchOrganisations(1, -1, {}).then((results: any) => {
					organisations = results;
					let organisationsMap = organisations.data.results.map((result: any) => {
						return {
							code: result.code,
							id: result.id,
							name: result.name + " - " + result.code,
							types: result.types
						};
					});
					organisations.data.results = organisationsMap;
				});
				return organisations;
			}
			case "organisationTypes": {
				return OrganisationsApi.fetchOrganisationTypes();
			}
			case "cashCentres": {
				return SafesApi.fetchCashCentres();
			}
			case "bankStatementEntries": {
				return SafesApi.fetchBankStatementEntries(0, 500);
			}
			case "cits": {
				return SafesApi.fetchCITProviders();
			}
			case "provinces": {
				return LookupsApi.fetchProvinces();
			}
			default: {
				// TODO: Log error
			}
		}
	}
}
