<template>
	<S2SForm title="Clients" v-if="safeTypes">
		<v-layout justify-end>
			<v-flex xs12 sm6 class="mb-2">
				<v-text-field label="Filter Clients" v-model="search" clearable messages="Client Name, Safe Serial, Vendor &amp; CIT Code"></v-text-field>
			</v-flex>
		</v-layout>
		<v-data-table :headers="clientHeaders" :items="allClients" class="elevation-1" :options.sync="pagination" :search="search">
			<template v-slot:item.safetype="{ item }">
				{{ getValue("safeType", item.safetype) }}
			</template>
			<template v-slot:item.cashcentreId="{ item }">
				{{ getValue("cashCentre", item.cashcentreId) }}
			</template>
			<template v-slot:item.citid="{ item }">
				{{ getValue("cit", item.citid) }}
			</template>
			<template v-slot:item.collectiondays="{ item }">
				{{ getValue("collectiondays", item.collectiondays) }}
			</template>
			<template v-slot:item.active="{ item }">
				<v-icon color="accent" v-if="item.active" medium>mdi-checkbox-marked</v-icon>
				<v-icon v-else-if="!item.active" medium>mdi-close-box</v-icon>
			</template>
			<template v-slot:item.summary="{ item }">
				<v-btn text medium @click="viewSafeSummary(item.id)">
					<v-icon>mdi-eye</v-icon>
				</v-btn>
			</template>
			<template v-slot:item.edit="{ item }">
				<v-btn text medium @click="editClient(item.id)">
					<v-icon>edit</v-icon>
				</v-btn>
			</template>
		</v-data-table>
	</S2SForm>
</template>

<script>
import Vue from "vue";

import { Client, CashCentre, CITProvider, SafesState } from "@/store/modules/safes";
import safeClientSchema from "../../static/config/forms/safe-create-form.json";

import { Status } from "@/store/utils";
import { Utils } from "@/utils";

import { LookupsApi } from "@/api/lookups-api";
import { state } from "../../store/organisations/users";

export default Vue.extend({
	name: "ViewClients",

	data: function() {
		return {
			pagination: {
				descending: true,
				itemsPerPage: 10
			},
			search: "",
			safeTypes: [],
			safeClientSchema: safeClientSchema,
			lookupsApi: LookupsApi.fetchLookups,
			clientHeaders: [
				{ text: "Client Name", value: "description" },
				{ text: "Safe Serial", value: "safeserial" },
				{ text: "Safe Type", value: "safetype", width: 105 },
				{ text: "Vendor", value: "vendordescription" },
				{ text: "Cash Centre", value: "cashcentreId" },
				{ text: "CIT", value: "citid" },
				{ text: "CIT code", value: "citcode" },
				{ text: "Collection Days", value: "collectiondays", width: 128 },
				{ text: "Active", value: "active" },
				{ text: "Safes Summary", value: "summary", align: "center" },
				{ text: "", value: "edit" }
			]
		};
	},

	computed: {
		allClients: function() {
			return this.$store.state.safes.allClients;
		},
		cashCentres: function() {
			return this.$store.state.safes.cashCentres;
		},
		citProviders: function() {
			return this.$store.state.safes.citProviders;
		}
	},

	mounted: function() {
		this.$store.dispatch("safes/fetchCITProviders");
		this.$store.dispatch("safes/fetchCashCentres");
		this.$store.dispatch("safes/fetchClients");

		this.lookupsApi("safeTypes").then(res => {
			this.safeTypes = res.data;
		});
	},

	methods: {
		editClient(id) {
			let clientId = id.toString();
			this.$router.push({ name: "safes-client-edit", params: { clientId: clientId } });
		},
		viewSafeSummary(id) {
			let clientId = id.toString();
			this.$router.push({ name: `safes-summary-client`, params: { clientId: clientId } });
		},
		getValue(type, id) {
			let value = "";
			let days = [];
			switch (type) {
				case "collectiondays":
					if (id.monday) {
						days.push("Mon");
					}
					if (id.tuesday) {
						days.push("Tue");
					}
					if (id.wednesday) {
						days.push("Wed");
					}
					if (id.thursday) {
						days.push("Thu");
					}
					if (id.friday) {
						days.push("Fri");
					}
					if (id.saturday) {
						days.push("Sat");
					}
					if (id.sunday) {
						days.push("Sun");
					}

					if (days.length > 0) {
						value = days.join(", ");
					} else {
						value = "not set";
					}

					break;
				case "safeType":
					value = this.safeTypes.filter(e => e.id === id)[0].description;
					break;
				case "cashCentre":
					value = this.cashCentres.filter(e => e.id === id)[0].description;
					break;
				case "cit":
					value = this.citProviders.filter(e => e.id === id)[0].description;
					break;
			}
			return value;
		}
	}
});
</script>
