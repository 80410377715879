var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.safeTypes)?_c('S2SForm',{attrs:{"title":"Clients"}},[_c('v-layout',{attrs:{"justify-end":""}},[_c('v-flex',{staticClass:"mb-2",attrs:{"xs12":"","sm6":""}},[_c('v-text-field',{attrs:{"label":"Filter Clients","clearable":"","messages":"Client Name, Safe Serial, Vendor & CIT Code"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.clientHeaders,"items":_vm.allClients,"options":_vm.pagination,"search":_vm.search},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.safetype",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValue("safeType", item.safetype))+" ")]}},{key:"item.cashcentreId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValue("cashCentre", item.cashcentreId))+" ")]}},{key:"item.citid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValue("cit", item.citid))+" ")]}},{key:"item.collectiondays",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getValue("collectiondays", item.collectiondays))+" ")]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [(item.active)?_c('v-icon',{attrs:{"color":"accent","medium":""}},[_vm._v("mdi-checkbox-marked")]):(!item.active)?_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-close-box")]):_vm._e()]}},{key:"item.summary",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","medium":""},on:{"click":function($event){return _vm.viewSafeSummary(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1)]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","medium":""},on:{"click":function($event){return _vm.editClient(item.id)}}},[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,1061371943)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }